<template>
  <div v-if="!isProduction || userIsSuperAdmin">
    <b-button v-b-toggle.debug variant="link" class="text-danger" size="sm"><i class='bx bx-bug' ></i> Debug</b-button>
    <b-button v-if="processable" @click="process" variant="link" class="text-info" size="sm"><i class='bx bx-bolt-circle' ></i> Process</b-button>
    <slot />
    <b-collapse id="debug" class="mt-2">
      <b-card v-if="item">
        <h5>Item:</h5>
        <pre><code>{{item}}</code></pre>
        <h5>Meta data:</h5>
        <pre><code>{{item.$meta}}</code></pre>
      </b-card>
      <b-card v-if="data">
        <div v-for="(d,header) in data" :key="header">
          <h5>{{header | capitalize}}:</h5>
          <pre><code>{{d}}</code></pre>
        </div>
      </b-card>
      <b-card v-if="$slots.default">
        <h5>Debug</h5>
        <pre><code><slot/></code></pre>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Resource from '@/services/Resource'
export default {
  props: {
    item: {
      type: Resource,
      required: false
    },
    data: {
      type: Object,
      required: false
    },
  },
  computed: {
    ...mapGetters({
      userIsSuperAdmin: 'auth/isSuperAdmin',
    }),
    processable() {
      return this.item && this.item.$exists && this.item.$meta.actions.process;
    }
  },
  methods: {
    async process() {
      await this.item.$process()
      this.$root.$emit('item:refresh')
    }
  }
}
</script>