import Resource from "@/services/Resource";
import { deleteBatchAction, nameField } from "./common";

export default class LocationGroup extends Resource {
  static name = 'location_group'
  static icon = 'bxs-edit-location'

  static fields = [
    nameField(),
  ]

  static batchActions = [
    deleteBatchAction()
  ]

  static relations = [
    {
      name: 'locations',
      resource: 'locations'
    }
  ]
}

Resource.registerResource(LocationGroup)