export default (() => ({
  namespaced: true,
  state: {
    locationId: null,
    locationName: null,
  },
  actions: {
    setLocation({ commit }, location) {
      commit('setLocation', location)
    },
    unsetLocation({ commit }) {
      commit('setLocation', {
        id: null,
        name: null,
      })
    },
    
  },
  mutations: {
    setLocation(state, {id, name}) {
      state.locationId = id
      state.locationName = name
    },
  },
}))();