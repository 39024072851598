<template>
  <v-select
    v-model="internalValue"
    v-bind="{options, clearable, required, disabled, multiple, components, selectable}"
    v-on="$listeners"
    label="countryName"
    :reduce="option => option.countryShortCode"
    :class="{'compact': compact || phone}"
    appendToBody
  >
    <template #selected-option="country">
      <country-flag v-if="country.countryShortCode" :country="country.countryShortCode" size="normal" />&nbsp;
      <template v-if="compact">{{ country.countryShortCode }}</template>
      <template v-else-if="phone">{{ countryPrefix }}</template>
      <template v-else>{{ country.countryName }}</template>
    </template>
    <template #no-options>{{$t('ui.select-no-results')}}</template>
    <template #option="option">
      <country-flag :country="option.countryShortCode" size="normal" />
      {{ option.countryName }}
      <span v-if="phone" class="text-muted">{{getCountryPrefix(option.countryShortCode)}}</span>
    </template>
  </v-select>
</template>

<script>
import countries from 'country-region-data'
import CountryFlag from 'vue-country-flag'

import callingCodes from '@/assets/dialling_codes.json'

export default {
  components: {CountryFlag},
  props: {
    value: {
      type: String,
      default: null,
    },
    initialValue: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false
    },
    phone: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      options: countries,
    };
  },
  created() {
    if(this.internalValue && !this.validateInput(this.internalValue)) {
      this.internalValue = 'BS'
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value || this.initialValue
      },
      set(value) {
        this.$emit('input',value)
      }
    },
    components() {
      if(this.compact || this.phone) {
        return {
          OpenIndicator: false,
        }
      } else {
        return null
      }
    },
    countryPrefix() {
      return this.getCountryPrefix(this.internalValue)
    },
  },
  methods: {
    getCountryPrefix(countryCode) {
      let callingCode = callingCodes[countryCode]
  
      return callingCode ? `+${callingCode}` : null
    },
    validateInput(value) {
      if(value && countries.find(c => c.countryShortCode == value)) {
        return value
      } else {
        return null
      }
    }
  }
};
</script>