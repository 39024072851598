<template>
  <b-form-group>
    <template #description>
      <span v-html="description"></span>
    </template>
    <v-validate ref="validator" v-bind="{name, rules, immediate}" v-slot="{ errors, touched, valid }">
      <legend v-if="label" tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
        <b-badge v-if="required" class="float-right text-uppercase font-weight-normal" :variant="touched && !valid ? 'danger' : 'light'">required</b-badge>
        <i v-if="icon" :class="['bx', icon]" />
        {{ $t(label) | ucfirst}}
        <span v-if="subLabel" class="text-muted small">{{subLabel}}</span>
      </legend>

      <slot>
        <select-item
          v-if="type == 'item'"
          v-model="internalValue"
          v-bind="{name, resourceName, scope, clearable, required, disabled, multiple, selectable, refreshOn, disabledValue, metaOptions, placeholder}"
          :class="stateClass(rules, touched, valid)"
          @search:blur="touch"
        />
        <select-country 
          v-else-if="type == 'country'"
          v-model="internalValue" 
          v-bind="{name, clearable, required, disabled, multiple, selectable, compact, disabledValue, placeholder}"
          :class="stateClass(rules, touched, valid)"
          @search:blur="touch"
        />
        <select-options
          v-else-if="type == 'select'"
          v-model="internalValue" 
          v-bind="{name, options, clearable, required, disabled, multiple, selectable, disabledValue, bitmask, reduce, placeholder}"
          :class="stateClass(rules, touched, valid)"
          @search:blur="touch"
        />
        <b-form-checkbox 
          v-else-if="type == 'checkbox'"
          v-model="internalValue"
          v-bind="{name, size,switch: $props.switch,value: checkedValue,uncheckedValue, disabled}"
        />
        <template v-else-if="type === 'tel'">
          <b-input-group :prepend="countryPrefix">
            <b-form-input
              v-model="internalValue"
              v-bind="{name, type, required, disabled}"
              :state="state(rules, touched, valid)"
              @keypress="onKeypress"
            ></b-form-input>
          </b-input-group>
        </template>
        <b-form-input
          v-else-if="type === 'coordinate'"
          type="number"
          min="-180"
          max="180"
          step="0.000001"
          v-model="internalValue"
          v-bind="{name, type, required, disabled, placeholder}"
          :state="state(rules, touched, valid)"
          @keypress="onKeypress"
        ></b-form-input>
        <b-form-textarea
          v-else-if="type === 'textarea'"
          v-model="internalValue"
          v-bind="{name, type, required, disabled, placeholder}"
          :state="state(rules, touched, valid)"
          @keypress="onKeypress"
          :rows="3"
        ></b-form-textarea>
        <b-form-file
          v-else-if="type === 'file'"
          v-model="internalValue"
          v-bind="{name, type, required, disabled, placeholder, dropPlaceholder, accept}"
          :state="state(rules, touched, valid)"
          @click="touch"
        ></b-form-file>
        <b-form-datepicker 
          v-else-if="type == 'date'"
          v-model="internalValue"
          nav-button-variant="dark"
          :hide-header="true"
          :locale="currentLocale"
          :state="state(rules, touched, valid)"
          v-bind="{name, required, disabled, placeholder, ...calendarLabels}"
          @hidden="touch"
        />
        <b-form-input
          v-else
          v-model="internalValue"
          v-bind="{name, type, required, disabled, placeholder, minlength, maxlength}"
          :state="state(rules, touched, valid)"
          @keypress="onKeypress"
        ></b-form-input>
      </slot>

      <b-form-invalid-feedback v-if="!noErrors" :state="state(rules, touched, valid)">
        <ul class="list-unstyled m-0">
          <li v-for="(error,i) in errors" :key="i" v-text="error" />
        </ul>
      </b-form-invalid-feedback>

    </v-validate>
  </b-form-group>
</template>

<script>
import SelectItem from "@/components/Form/SelectItem";
import SelectCountry from './SelectCountry'
import SelectOptions from './SelectOptions'
import { 
  ValidationProvider as vValidate, 
} from 'vee-validate';
import callingCodes from '@/assets/dialling_codes.json'
import i18n from '@/i18n';

export default {
  components: {
    vValidate,
    SelectOptions,
    SelectItem,
    SelectCountry
    
  },
  props: {
    name: String,
    label: String,
    subLabel: String,
    icon: String,
    disabled: Boolean,
    disabledValue: Object,
    description: String,
    noErrors: Boolean,
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [Number,String,Array,File,Boolean],
      default: null
    },
    rules: {
      type: [String,Object],
      default: undefined
    },
    immediate: Boolean,
    resourceName: String,
    scope: [String,Array,Object],
    reduce: Function,
    options: Array,
    metaOptions: Array,
    clearable: {
      type: Boolean,
      default: true,
    },
    compact: Boolean,
    numeric: Boolean,
    multiple: Boolean,
    bitmask: Boolean,
    errors: {
      type: Array,
      default: Array
    },
    switch: Boolean,
    country: String,
    size: String,
    checkedValue: [String,Number],
    uncheckedValue: [String,Number],
    refreshOn: Number,
    placeholder: String,
    dropPlaceholder: String,
    accept: String,
    maxlength: Number,
    minlength: Number,
    selectable: {
      type: Function,
      default: undefined
    }
  },
  // watch: {
  //   value(newValue) {
  //     this.internalValue = newValue
  //   },
  //   internalValue() {
  //     this.touch()
  //   }
  // },
  // data() {
  //   return {
  //     internalValue: this.value,
  //   }
  // },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input',value)
        this.touch()
      }
    },
    required() {
      if(!this.rules) {
        return false
      } else if(typeof this.rules == 'string') {
        return this.rules ? !!this.rules.match(/\brequired\b/g) : false
      } else {
        return this.rules.required
      }
    },
    countryPrefix() {
      if(this.country) {
        let callingCode = callingCodes[this.country.toUpperCase()]
  
        return callingCode ? `+${callingCode}` : null
      } else {
        return null
      }
    },
    currentLocale() {
      return i18n.locale
    },
    calendarLabels() {
      return this.$i18n.messages[this.$i18n.locale].calendar
    }
  },
  methods: {
    touch() {
      this.$refs.validator.flags.touched = true
    },
    onKeypress(val) {
      if(this.numeric && isNaN(Number(val.key))) {
        return val.preventDefault();
      }
    },
    state(rules, touched, valid) {
      return rules && touched 
        ? (valid ? (!this.internalValue && !this.required ? null : true) : false) 
        : null
    },
    stateClass(rules, touched, valid) {
      let state = this.state(rules, touched, valid)
      if(state === true) return 'is-valid'
      else if(state === false) return 'is-invalid'
      else return null

    }
  }
}
</script>