import Shipment from '@/resources/Shipment'
import Package from '@/resources/Package'
import Manifest from '@/resources/Manifest'
import Location from '@/resources/Location'
import LocationGroup from '@/resources/LocationGroup'
import Merchant from '@/resources/Merchant'
import Batch from '@/resources/Batch'
import User from '@/resources/User'
import BatchItem from '@/resources/BatchItem'

export default [
    {
        name: 'signin',
        path: '/signin',
        component: () => import(/* webpackChunkName: "signin", webpackMode: "eager" */ `@/pages/SignIn`),
        meta: {
            guest: true
        }
    },
    {
        name: 'reset-password',
        path: '/reset-password',
        component: () => import(/* webpackChunkName: "reset-password" */ `@/pages/ResetPassword`),
        meta: {
            guest: true
        }
    },
    {
        name: 'create-password',
        path: '/create-password',
        component: () => import(/* webpackChunkName: "create-password" */ `@/pages/CreatePassword`),
        meta: {
            guest: true
        }
    },
    {
        name: 'dashboard',
        path: '/',
        component: () => import(/* webpackChunkName: "dashboard", webpackMode: "eager" */ `@/pages/Dashboard`),
    },
    {
        name: 'pudo',
        path: '/pudo',
        component: () => import(/* webpackChunkName: "pudo" */ `@/pages/PUDO`),
    },
    {
        name: 'warehouse',
        path: '/warehouse',
        component: () => import(/* webpackChunkName: "warehouse" */ `@/pages/Warehouse`),
    },
    {
        name: 'driver',
        path: '/driver',
        component: () => import(/* webpackChunkName: "driver" */ `@/pages/Driver`),
    },
    {
        name: 'lockers',
        path: '/lockers',
        component: () => import(/* webpackChunkName: "lockers" */ `@/pages/Lockers`),
    },
    ...Shipment.routes,
    ...Manifest.routes,
    ...Location.routes,
    ...LocationGroup.routes,
    ...Merchant.routes,
    ...Package.routes,
    ...Batch.routes,
    ...BatchItem.routes,
    {
        name: `users-profile`,
        path: `/users/profile`,
        component: () => import(`@/pages/users/Edit`),
        meta: {
          mode: 'edit',
          profile: true,
          resource: User, 
        }
    },
    ...User.routes,
]