<template>
  <b-modal 
    ref="modal" 
    size="lg"
    ok-only
  >
    <template #modal-title>
      {{$t('users.token.title')}}
    </template>
      <b-alert show>
        {{$t('users.token.info')}}
      </b-alert>

      <input onClick="this.select();" class="form-control form-control-lg bg-white mt-2 text-center" label="asd" readonly :value="token" />
      

  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"

export default {
  name: 'Token',
  extends: Modal,
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  mounted() {
  },
  data() {
    return {
      finished: false,
    }
  },
  methods: {
  }
}
</script>