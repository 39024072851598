<template>
  <b-modal 
    ref="modal" 
    :no-close-on-backdrop="running"
    :no-close-on-esc="running"
    :hide-header-close="running"
  >
    <template #modal-title>
      <i class='bx bx-grid'></i>
      {{$t('terminals.create.title')}}
    </template>

    <form-input 
      type="select"
      label="terminals.create.select-layout" 
      icon="bx-layout"
      v-model="selectedLayout"
      :reduce="l => l"
      :options="lockerLayouts"
    />

    <template #modal-footer>
      <b-button @click="close(false)">
        {{$t('common.cancel')}}
      </b-button>
      <b-button :disabled="!selectedLayout || running" @click="run" variant="primary">
        <i v-if="running" class='bx bx-loader-circle bx-spin'></i>
        {{$t('terminals.create.create-button')}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import FormInput from "@/components/Form/Input";
import lockerLayouts from '@/assets/locker_layouts.json'
import Resource from '@/services/Resource';

export default {
  name: 'CreateTerminal',
  extends: Modal,
  components: {FormInput},
  props: {
    resource: {
      type: Function,
      required: true,
    },
    item: {
      type: Resource,
      required: true,
    },
  },
  data() {
    return {
      lockerLayouts,
      selectedLayout: null,
      running: false,
    }
  },
  methods: {
    async run() {
      this.running = true
      try {
        await this.item.$createTerminal(this.selectedLayout)
        this.close(true)
      } finally {
        this.running = false
      }
    }
  }
}
</script>