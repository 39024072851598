import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import pudo from './modules/pudo';
import warehouse from './modules/warehouse';
import users from './modules/users';
import shipments from './modules/shipments';
import manifests from './modules/manifests';
import locations from './modules/locations';
import location_groups from './modules/location_groups';
import merchants from './modules/merchants';
import batch_items from './modules/batch_items';
import batches from './modules/batches';
import i18n from '../i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: 'en',
    activitiesReversed: true,
  },
  getters: {
    locale: state => state.locale
  },
  actions: {
    setLocale({ commit }, locale) {
      commit('setLocale', locale)
    },
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale
      i18n.locale = locale
    },
    setActivitiesReversed: (state,reversed) => {
      state.activitiesReversed = reversed
    },
  },
  modules: {
    auth,
    pudo,
    warehouse,
    users,
    shipments,
    manifests,
    locations,
    location_groups,
    merchants,
    batches,
    batch_items,
  },
  plugins: [createPersistedState({
    rehydrated: state => {
      i18n.locale = state.getters.locale
    }
  })]
});