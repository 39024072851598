import store from '@/store'
import Resource from "@/services/Resource";
import Token from "@/components/Modals/Token";
import i18n, { availableLocales } from '@/i18n';
import { activeField, activeFilter, deleteBatchAction, merchantField, merchantFilter, nameField } from './common';
import { modal } from '@/utils';
import api from '@/api';

export const ROLE_SUPERADMIN       = 1
export const ROLE_CUSTOMER_SUPPORT = 2
export const ROLE_MERCHANT_ADMIN   = 4
export const ROLE_MERCHANT_USER    = 8
export const ROLE_PUDO             = 16
export const ROLE_PUBLIC           = 32
export const ROLE_DRIVER           = 64
export const ROLE_WAREHOUSE        = 128;

export const ROLE_ADMIN    = ROLE_SUPERADMIN | ROLE_CUSTOMER_SUPPORT
export const ROLE_MERCHANT = ROLE_MERCHANT_ADMIN | ROLE_MERCHANT_USER
export const ROLE_ALL      = ROLE_SUPERADMIN | ROLE_CUSTOMER_SUPPORT | ROLE_MERCHANT_ADMIN | ROLE_MERCHANT_USER

export const roleMap = {
  [ROLE_SUPERADMIN]: 'role.superadmin',
  [ROLE_MERCHANT_ADMIN]: 'role.merchant_admin',
  [ROLE_MERCHANT_USER]: 'role.merchant',
  [ROLE_CUSTOMER_SUPPORT]: 'role.customer_support',
  [ROLE_PUDO]: 'role.pudo',
  [ROLE_DRIVER]: 'role.driver',
  [ROLE_WAREHOUSE]: 'role.warehouse',
}

export default class User extends Resource {
  static name = 'user'
  static icon = 'bx-group'

  static relations = [
    {
      name: 'locations',
      resource: 'locations'
    },
    {
      name: 'packages',
      resource: 'packages'
    }
  ]

  static fields = [
    nameField('last_name'),
    { 
      key: 'email', 
      sortable: true,
      label: 'common.email'
    },
    { 
      key: 'role', 
      sortable: true,
      label: 'users.role',
      formatter: (value) => i18n.t(roleMap[value])
    },
    activeField(),
    merchantField(),
  ]
  
  static filters = [
    merchantFilter(),
    activeFilter(),
    {
      $roles: ROLE_ADMIN,
      name: 'role',
      label: 'users.role',
      type: 'select',
      options: this.roles,
    },
  ]


  static actions = [
    {
      name: 'request-token',
      icon: 'bxs-key',
      async action(item) {
        await item.$requestToken()
        return {refresh: false}
      }
    }
  ]

  static batchActions = [
    deleteBatchAction()
  ]

  get roleName() {
    return roleMap[this.role]
  }

  static get locales() {
    return availableLocales
  }

  get defaults() {
    let user = store.getters['auth/user']

    return {
      active: 1,
      merchant_id: user?.merchant_id || null,
      preferences: {}
    }
  }

  get name() {
    return `${this.first_name} ${this.last_name}`
  }

  get isSuperAdmin() {
    return this.role & ROLE_SUPERADMIN
  }

  get isMerchant() {
    return this.role & ROLE_MERCHANT
  }

  get isPUDO() {
    return this.role & ROLE_PUDO
  }
  
  get isWarehouse() {
    return this.role & ROLE_WAREHOUSE
  }

  get isDriver() {
    return this.role & ROLE_DRIVER
  }

  get isStore() {
    return this.role & (ROLE_WAREHOUSE | ROLE_PUDO)
  }


  static get roles() {
    return [
      {label: i18n.t(roleMap[ROLE_SUPERADMIN]), code: ROLE_SUPERADMIN},
      {label: i18n.t(roleMap[ROLE_CUSTOMER_SUPPORT]), code: ROLE_CUSTOMER_SUPPORT},
      {label: i18n.t(roleMap[ROLE_MERCHANT_ADMIN]), code: ROLE_MERCHANT_ADMIN},
      {label: i18n.t(roleMap[ROLE_MERCHANT_USER]), code: ROLE_MERCHANT_USER},
      {label: i18n.t(roleMap[ROLE_PUDO]), code: ROLE_PUDO},
      {label: i18n.t(roleMap[ROLE_WAREHOUSE]), code: ROLE_WAREHOUSE},
      {label: i18n.t(roleMap[ROLE_DRIVER]), code: ROLE_DRIVER},
    ]
  }

  async $requestToken() {
    let {data} = await api.get(`users/${this.id}/request-token`)

    await modal(Token,{token: data.token})
  }
}

export const roleFilter = obj => !obj.$roles || store?.getters['auth/role'] & obj.$roles
export const featureFilter = obj => !obj.$feature || store?.getters['auth/features'] & obj.$feature

Resource.registerResource(User)