import { ROLE_ADMIN } from "./User";
import Resource from "@/services/Resource";
import store from "@/store";
import i18n from "@/i18n";
import { activeFilter, deleteBatchAction, idField, merchantField, merchantFilter } from "./common";
import api from "@/api";
import CreateTerminal from "@/components/Modals/CreateTerminal"


export const TYPE_DROPOFF = 'dropoff'
export const TYPE_PICKUP = 'pickup'

export const CLASS_LOCKER = 'locker'
export const CLASS_PUDO = 'pudo'
export const CLASS_WAREHOUSE = 'warehouse'

export const SERVICE_SMARTBOX_V3 = 'smartbox-v3'
export const SERVICE_SMARTBOX_V4 = 'smartbox-v4'

export default class Location extends Resource {
  static name = 'location'
  static icon = 'bx-map'

  static relations = [
    {
      name: 'packages',
      resource: 'packages'
    }
  ]

  static get defaultTableState() {
    return {
      ...super.defaultTableState,
      scope: TYPE_DROPOFF,
    }
  }

  static fields = [
    {
      ...idField(),
      $scopes: ['dropoff'],
    },
    { 
      key: 'locations:name', 
      sortable: true,
      sortKey: 'name',
      label: 'common.name',
    },
    { 
      $scopes: ['pickup'],
      key: 'active', 
      label: 'locations.active',
      formatter: (v,k,item) => item.active
    },
    { 
      $scopes: ['dropoff'],
      $roles: ROLE_ADMIN,
      key: 'active', 
      label: 'locations.active',
      formatter: (v,k,item) => item.active
    },
    { 
      $scopes: ['pickup'],
      $roles: ROLE_ADMIN,
      key: 'approved', 
      label: 'locations.approved',
      formatter: (v,k,item) => item.approved
    },
    { 
      $scopes: ['dropoff'],
      $roles: ROLE_ADMIN,
      key: 'code', 
      sortable: true,
      label: 'locations.code',
    },
    { 
      $scopes: ['dropoff'],
      $roles: ROLE_ADMIN,
      key: 'package_count', 
      sortable: false,
      label: 'packages.title',
    },
    { 
      $scopes: ['dropoff'],
      key: 'class', 
      sortable: true,
      label: 'locations.class',
      formatter: (value) => value ? i18n.t(`locations.classes.${value}`) : null,
    },
    { 
      $scopes: ['dropoff'],
      key: 'locker_service', 
      sortable: true,
      label: 'locations.locker_service',
      formatter: (value) => value ? i18n.t(`locations.locker_services.${value}`) : null,
    },
    {
      ...merchantField(),
      $scopes: ['pickup'],
    }
  ]
  
  static filters = [
    merchantFilter(),
    activeFilter(),
    {
      $roles: ROLE_ADMIN,
      name: 'approved',
      label: 'locations.approved',
      default: false,
      type: 'boolean',
    },
    {
      $roles: ROLE_ADMIN,
      name: 'locker_service',
      label: 'locations.locker_service',
      type: 'select',
      options: this.locker_services
    },
    {
      name: 'class',
      label: 'locations.class',
      type: 'select',
      options: this.classes
    }
  ]

  static actions = [
    {
      name: 'approve',
      icon: 'bx-list-check',
      variant: 'info',
      async action(item) {
        await item.$approve()

        return {refresh: true}
      }
    },
    {
      name: 'createTerminal',
      label: 'locations.actions.create-terminal',
      icon: 'bx-grid',
      variant: 'info',
      modal: CreateTerminal,
    },
  ]

  static batchActions = [
    deleteBatchAction()
  ]

  async $approve() {
    await api.post(`locations/${this.id}/approve`)

    return true
  }

  async $createTerminal(terminalLayout) {
    await api.post(`locations/${this.id}/create-terminal`,{ terminalLayout })

    return true
  }

  get defaults() {
    const isSuperAdmin = store.getters['auth/isSuperAdmin']
    const user = store.getters['auth/user']

    return {
      type: isSuperAdmin ? TYPE_DROPOFF : TYPE_PICKUP,
      active: 1,
      merchant_id: user.merchant_id || null,
      address: {
        country: 'BS'
      },
    }
  }

  get isPickup() {
    return this.type === TYPE_PICKUP
  }

  get isDropoff() {
    return this.type === TYPE_DROPOFF
  }

  get isLocker() {
    return this.class === CLASS_LOCKER
  }

  get isPUDO() {
    return this.class === CLASS_PUDO
  }

  get isWarehouse() {
    return this.class === CLASS_WAREHOUSE
  }

  get isStore() {
    return this.isDropoff && (this.isPUDO || this.isWarehouse)
  }

  static get types() {
    return [
      { label: `locations.types.${TYPE_PICKUP}`, code: TYPE_PICKUP },
      { label: `locations.types.${TYPE_DROPOFF}`, code: TYPE_DROPOFF },
    ]
  }

  static get classes() {
    return [
      { label: `locations.classes.${CLASS_LOCKER}`, code: CLASS_LOCKER },
      { label: `locations.classes.${CLASS_PUDO}`, code: CLASS_PUDO },
      { label: `locations.classes.${CLASS_WAREHOUSE}`, code: CLASS_WAREHOUSE },
    ]
  }

  static get locker_services() {
    return [
      { label: `locations.locker_services.${SERVICE_SMARTBOX_V3}`, code: SERVICE_SMARTBOX_V3 },
      { label: `locations.locker_services.${SERVICE_SMARTBOX_V4}`, code: SERVICE_SMARTBOX_V4 },
    ]
  }

  static get scopes() {
    return this.types
  }
}

Resource.registerResource(Location)