/*eslint no-fallthrough: ["error", { "commentPattern": "break[\\s\\w]*omitted" }]*/

import axios from 'axios';
import router from './router';
import store from './store';
import app from './main';
import qs from 'qs';
import { clean, vibrate } from './utils';

const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_HOST}api/`,
  withCredentials: true
});

api.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return qs.stringify(clean(params,true), {
      arrayFormat: "indices",
      encode: false
    })
  }

  return config
})

api.interceptors.response.use(undefined, async (error) => {
  console.error(error,error.response)
  let data = error.response.data,
      message

  if(data instanceof Blob){
    data = JSON.parse(await data.text())
  }

  if(error.config.suppress?.includes(error.response.status)) {
    return Promise.reject(error);
  }

  vibrate()

  switch(error.response.status) {
    case 400:
    case 422:
      if(app.$root.suppress400Errors) {
        break
      }

      if(data.code && app.$te(data.code)) {
        message = app.$t(data.code)
      } else {
        message = data.code ? `[${data.code}] ${data.message} ` : `${data.message} `
      }

      if(typeof data.errors == 'object') {
        message += Object.values(data.errors)
      }

      app.$root.$bvModal.msgBoxOk(message,{
        autoFocusButton: 'ok',
        okVariant: 'danger'
      })
      break

    case 401:
      await store.dispatch('auth/signOut')
      router.push({name: 'signin'})
      break

    case 403:
      if(app.$te(data.message)) {
        message = app.$t(data.message)
      } else {
        message = app.$t('errors.api-403',{message:data.message})
      }
      
      app.$root.$bvModal.msgBoxOk(message,{
        autoFocusButton: 'ok',
        okVariant: 'danger'
      })
      break
    case 404:
      app.$root.$bvModal.msgBoxOk(app.$t('errors.api-404',{message:data.message}),{
        autoFocusButton: 'ok',
        okVariant: 'danger'
      })
      break

    case 409:
      app.$root.$bvModal.msgBoxOk(app.$t('errors.api-409',{message:data.message}),{
        autoFocusButton: 'ok',
        okVariant: 'danger'
      })
      break

    case 500:
    default:
      app.$root.$bvModal.msgBoxOk(app.$t('errors.api-500',{message:data.message}),{
        autoFocusButton: 'ok',
        okVariant: 'danger'
      })
  }

  return Promise.reject(error);
});

export default api