<script>
import router from '@/router'
export default {
  created() {
    this.$on('bv::modal::hide',(e,modalId) => {
      if(modalId === this.$refs.modal.localId_) {
        this.resolve(false)
        this.$destroy()
      }
    })

    this.$nextTick(function() {
      let element = document.createElement('div')
      this.$mount(element)
      this.$refs.modal.show()
    })

    const unregisterRouterGuard = router.beforeEach((to, from, next) => {
      this.$refs.modal.hide()
      next(!!to.params.exitModal)
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },
  methods: {
    close(response = true){
      this.resolve(response)
      this.$refs.modal.hide()
    }
  }
}
</script>