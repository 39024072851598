<template>
  <router-view class="app" :class="`env-${env}`"></router-view>
</template>

<script>

export default {
  name: "App",
  metaInfo() {
    let titleTemplate
    if(process.env.NODE_ENV === 'production') {
      titleTemplate = 'Clickbox · %s'
    } else {
      titleTemplate = `[Clickbox ${process.env.NODE_ENV}] · %s`
    }

    return {
      titleTemplate
    }
  },
  computed: {
    env() {
      return process.env.NODE_ENV
    }
  }
}
</script>

<style>
</style>
