<template>
  <b-modal 
    ref="modal" 
    no-close-on-backdrop 
    no-close-on-esc 
    hide-header-close
    :hide-footer="!failed"
  >
    <template #modal-title>
      {{$t(`actions.print.processing`,{items: $tc(`shipments.pluralize`,items.length)})}}
    </template>

    <span v-if="finished" v-text="$t(`actions.print.finished`,{items: $tc(`shipments.pluralize`,items.length)})" />
    <span v-else v-text="$t(`actions.print.${step}`,{items: $tc(`shipments.pluralize`,total)})" />

    <b-progress :value="progress" :max="total" :animated="!finished" variant="info" />

    <b-alert variant="danger" :show="failed" class="mt-4">
      {{$t('actions.print.error',{message: error, ref: currentItem.external_reference_id})}}
    </b-alert>

    <template #modal-footer>
      <b-button v-if="failed" @click="close(!failed)">
        {{$t('common.ok')}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import Shipment, { STATUS_REGISTERED } from '@/resources/Shipment'
import app from '@/main';

const STEP_REGISTERING = 'registering'
const STEP_PRINTING = 'printing'

export default {
  name: 'Action',
  extends: Modal,
  props: {
    action: {
      type: Object,
      required: true,
    },
    resource: {
      type: Function,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.register()
  },
  data() {
    return {
      step: STEP_REGISTERING,
      total: 1,
      progress: 0,
      finished: false,
      failed: false,
      error: null,
      currentItem: {},
    }
  },
  methods: {
    async register() {
      let unregistered = this.items.filter(item => item.status < STATUS_REGISTERED)
      this.total = unregistered.length

      app.$root.suppress400Errors = true

      try {
        for(let item of unregistered) {
          this.currentItem = item
          await item.$register()
          this.progress++
        }
        this.run()
      } catch (error) {
        if(error.response?.data) {
          let message = error.response?.data?.message
          let code = error.response?.data?.code
          if(this.$te(code)) {
            this.error = this.$t(code)
          } else {
            this.error = code ? `[${code}] ${message}` : message
          }
        }
        this.failed = true
      }
      app.$root.suppress400Errors = false
    },
    async run() {
      this.step = STEP_PRINTING
      this.total = this.items.length
      this.progress = this.items.length

      await Shipment.bulkPrint(this.items.map(item => item.id))
      this.finished = true
      this.close(true)
    }
  }
}
</script>