import Resource from "@/services/Resource"
import i18n from "@/i18n"
import router from "@/router"
import Shipment from "./Shipment"
import api from "@/api"
import RerouteModal from '@/components/Modals/Reroute.vue'
import printer from "@/printer"
import { FEATURE_POSTPAYMENTS } from "./Merchant"

export const SIZE_SMALL = 'SMALL'
export const SIZE_MEDIUM = 'MEDIUM'
export const SIZE_LARGE = 'LARGE'
export const SIZE_OVERSIZED = 'OVERSIZED'

export const STATUS_LOST                = -200
export const STATUS_RETURNED            = -100
export const STATUS_CANCELLED           = -90
export const STATUS_EXPIRED_COLLECTED   = -50
export const STATUS_EXPIRED_COLLECTABLE = -45
export const STATUS_EXPIRED             = -40
export const STATUS_REPLACED            = -5
export const STATUS_DRAFT               = 0
export const STATUS_REGISTERED          = 20
export const STATUS_AWAITING_PICKUP     = 30
export const STATUS_PICKED_UP           = 50
export const STATUS_IN_WAREHOUSE        = 60
export const STATUS_DROPPED_OFF         = 70
export const STATUS_AWAITING_PAYMENT    = 80
export const STATUS_READY_TO_COLLECT    = 90
export const STATUS_COLLECTED           = 100

export default class Package extends Resource {
  static name = 'package'
  static icon = 'bx-package'

  static parent = Shipment

  static routePrefix = '/shipments/:parent_id'

  static fields = [
    {
      key: 'barcode',
      label: 'packages.barcode',
    },
    { 
      key: 'size', 
      label: 'packages.size',
      formatter: (value) => i18n.t(`packages.sizes.${value}`)
    },
    {
      key: 'status',
      label: 'common.status',
    },

  ]

  static actions = [
    {
      name: 'print',
      icon: 'bx-printer',
      async action(item) {
        await item.$print()
        return {refresh: false}
      }
    },
    {
      name: 'notify',
      icon: 'bx-message-rounded-detail',
      async action(item) {
        await item.$notify()
        return {
          refresh: true,
          confirmation: 'packages.actions.notify-confirmation',
        }
      }
    },
    {
      name: 'reroute',
      icon: 'bx-repost',
      variant: 'info',
      modal: RerouteModal,
    },
    {
      name: 'extend-expiry',
      icon: 'bx-calendar-plus',
      variant: 'info',
      confirm: true,
      confirmText: 'packages.actions.extend-expiry-confirm-text',
      confirmOk: 'packages.actions.extend-expiry',
      async action(item) {
        await item.$extendExpiry()
        return {refresh: true}
      }
    },
    {
      name: 'expire',
      icon: 'bx-alarm-exclamation',
      variant: 'danger',
      confirm: true,
      confirmText: 'packages.actions.expire-confirm-text',
      confirmOk: 'packages.actions.expire',
      async action(item) {
        await item.$expire()
        return {refresh: true}
      }
    },
    {
      name: 'cancel',
      icon: 'bx-x-circle',
      variant: 'danger',
      confirm: true,
      confirmText: 'packages.actions.cancel-confirm-text',
      confirmOk: 'packages.actions.cancel-confirm-ok',
      async action(item) {
        await item.$cancel()
        return {refresh: true}
      }
    },
    {
      name: 'mark-as-lost',
      icon: 'bx-ghost',
      variant: 'danger',
      confirm: true,
      confirmText: 'packages.actions.mark-as-lost-confirm-text',
      confirmOk: 'packages.actions.mark-as-lost-confirm-ok',
      async action(item) {
        await item.$markAsLost()
        return {refresh: true}
      }
    },
    {
      name: 'mark-as-returned',
      icon: 'bx-undo',
      variant: 'warning',
      async action(item) {
        await item.$markAsReturned()
        return {refresh: true}
      }
    },
    {
      name: 'deconsolidate',
      icon: 'bx-unite',
      variant: 'danger',
      confirm: true,
      confirmText: 'packages.actions.deconsolidate-confirm-text',
      confirmOk: 'packages.actions.deconsolidate',
      async action(item) {
        await item.$deconsolidate()
        return {refresh: true}
      }
    },
  ]

  static relations = [
    {
      name: 'shipment',
      resource: 'shipments'
    },
    {
      name: 'consolidations',
      resource: 'packages'
    },
    {
      name: 'consolidated_to',
      resource: 'packages'
    },
  ]
  
  get badges() {
    const badges = []

    if(this.is_rerouted) {
      badges.push({
        label: 'packages.rerouted',
        variant: 'warning',
        icon: 'bx-git-pull-request',
      })
    }

    if(this.is_consolidated) {
      badges.push({
        label: 'packages.consolidated',
        variant: 'warning',
        icon: 'bx-unite',
      })
    }
    else if(this.is_consolidation) {
      badges.push({
        label: 'packages.consolidation',
        variant: 'info',
        icon: 'bx-unite',
      })
    }

    if(this.missing && this.status != STATUS_LOST) {
      badges.push({
        label: 'packages.missing',
        variant: 'danger',
        icon: 'bx-low-vision',
      })
    }

    if(this.is_unpaid) {
      badges.push({
        label: 'shipments.unpaid',
        variant: 'dark',
        icon: 'bx-coin',
      })
    }

    if(this.$meta.held_here) {
      badges.push({
        label: 'packages.held_here',
        variant: 'info',
        icon: 'bx-been-here',
      })
    }

    return badges
  }

  $open() {
    router.push({name: `${this.constructor.plural}-show`, params: { id: this.id, parent_id: this.shipment_id }})
  }

  async $getIdentificationImage() {
    const {data} = await api.get(`packages/${this.id}/identification-image`,{
      responseType: 'blob'
    })

    return window.URL.createObjectURL(data);
  }

  async $print() {
    let {data} = await api.post(`packages/${this.id}/print`,undefined,{ 
      responseType: 'blob',
    })

    printer.$emit('shipment:print',new Blob([data], {type: 'application/pdf'}))

    return true
  }

  async $reroute(locationId) {
    const {data: {data,meta}} = await api.post(`packages/${this.id}/reroute`,{
      location: locationId
    })

    this.$fill(data,meta)
  }

  async $cancel() {
    const {data: {data,meta}} = await api.post(`packages/${this.id}/cancel`)

    this.$fill(data,meta)
  }

  async $pickup() {
    const {data: {data,meta}} = await api.post(`packages/${this.id}/pickup`)

    this.$fill(data,meta)
  }

  async $notify() {
    await api.post(`packages/${this.id}/notify`)
  }

  async $process() {
    await api.post(`packages/${this.id}/process`)
  }

  async $runhook() {
    await api.post(`packages/${this.id}/runhook`)
  }

  async $forceSync() {
    await api.post(`packages/${this.id}/force-sync`)
  }

  async $markAsLost() {
    await api.post(`packages/${this.id}/mark-as-lost`)
  }

  async $markAsReturned() {
    await api.post(`packages/${this.id}/mark-as-returned`)
  }

  async $extendExpiry() {
    await api.post(`packages/${this.id}/extend-expiry`)
  }

  async $expire() {
    await api.post(`packages/${this.id}/expire`)
  }

  async $deconsolidate() {
    await api.post(`packages/${this.id}/deconsolidate`)
  }

  static get sizes() {
    return [
      { label: `packages.sizes.${SIZE_SMALL}`, code: SIZE_SMALL },
      { label: `packages.sizes.${SIZE_MEDIUM}`, code: SIZE_MEDIUM },
      { label: `packages.sizes.${SIZE_LARGE}`, code: SIZE_LARGE },
      { label: `packages.sizes.${SIZE_OVERSIZED}`, code: SIZE_OVERSIZED },
    ]
  }

  static get statuses() {
    return [
      { label: `packages.statuses.${STATUS_DRAFT}`, code: STATUS_DRAFT, icon: 'bx-file-blank' },
      { label: `packages.statuses.${STATUS_REGISTERED}`, code: STATUS_REGISTERED, timeline: true, icon: 'bx-printer' },
      { label: `packages.statuses.${STATUS_AWAITING_PICKUP}`, code: STATUS_AWAITING_PICKUP, timeline: true, icon: 'bx-upload' },
      { label: `packages.statuses.${STATUS_PICKED_UP}`, code: STATUS_PICKED_UP, timeline: true, icon: 'bxs-truck' },
      { label: `packages.statuses.${STATUS_IN_WAREHOUSE}`, code: STATUS_IN_WAREHOUSE, timeline: true, icon: 'bx-building' },
      { label: `packages.statuses.${STATUS_DROPPED_OFF}`, code: STATUS_DROPPED_OFF, timeline: true, icon: 'bx-download' },
      { label: `packages.statuses.${STATUS_AWAITING_PAYMENT}`, code: STATUS_AWAITING_PAYMENT, icon: 'bx-coin', $feature: FEATURE_POSTPAYMENTS },
      { label: `packages.statuses.${STATUS_READY_TO_COLLECT}`, code: STATUS_READY_TO_COLLECT, timeline: true, icon: 'bx-user-check' },
      { label: `packages.statuses.${STATUS_COLLECTED}`, code: STATUS_COLLECTED, timeline: true, icon: 'bx-check-circle' },

      { label: `packages.statuses.${STATUS_CANCELLED}`, code: STATUS_CANCELLED, variant: 'danger', icon: 'bx-x-circle' },
      { label: `packages.statuses.${STATUS_RETURNED}`, code: STATUS_RETURNED, variant: 'danger', icon: 'bx-undo' },

      { label: `packages.statuses.${STATUS_EXPIRED}`, code: STATUS_EXPIRED, variant: 'warning', icon: 'bx-alarm-exclamation' },
      { label: `packages.statuses.${STATUS_EXPIRED_COLLECTABLE}`, code: STATUS_EXPIRED_COLLECTABLE, variant: 'danger', icon: 'bx-calendar-x' },
      { label: `packages.statuses.${STATUS_EXPIRED_COLLECTED}`, code: STATUS_EXPIRED_COLLECTED, variant: 'danger', icon: 'bx-check-circle' },

      { label: `packages.statuses.${STATUS_LOST}`, code: STATUS_LOST, variant: 'danger', icon: 'bx-ghost' },
    ]
  }
}

Resource.registerResource(Package)