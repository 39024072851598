import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './lang/en.yaml'
import es from './lang/es.yaml'

export const availableLocales = [
  {
    code: 'en',
    label: 'locale.en',
  },
  {
    code: 'es',
    label: 'locale.es',
  }
]

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, es }
})

export default i18n