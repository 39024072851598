import Resource from "@/services/Resource";
import api from "@/api"
import Vue from "vue";
import printer from '@/printer'
import i18n from "@/i18n";
import { deleteBatchAction, merchantField, merchantFilter, nameField } from "./common";
import store from "@/store";
import BatchPrintModal from '@/components/Modals/BatchPrintModal'
import Export from '@/components/Modals/Export'
import { download } from "@/utils";
import { FEATURE_BATCHES, FEATURE_CONTENT, FEATURE_MANIFESTS, FEATURE_POSTPAYMENTS } from "./Merchant";

export const STATUS_LOST                      = -200
export const STATUS_RETURNED                  = -100
export const STATUS_CANCELLED                 = -90
export const STATUS_EXPIRED_COLLECTED         = -50
export const STATUS_EXPIRED_COLLECTABLE       = -45
export const STATUS_EXPIRED                   = -40
export const STATUS_DRAFT                     = 0
export const STATUS_REQUESTING_CUSTOMER_INPUT = 4
export const STATUS_AWAITING_CUSTOMER_INPUT   = 5
export const STATUS_REGISTRABLE               = 10
export const STATUS_PARTLY_REGISTERED         = 15
export const STATUS_REGISTERED                = 20
export const STATUS_AWAITING_PICKUP           = 30
export const STATUS_PICKED_UP                 = 50
export const STATUS_IN_WAREHOUSE              = 60
export const STATUS_DROPPED_OFF               = 70
export const STATUS_AWAITING_PAYMENT          = 80
export const STATUS_READY_TO_COLLECT          = 90
export const STATUS_COLLECTED                 = 100

export const TYPE_STANDARD = 'standard'
export const TYPE_EXPRESS = 'express'

export const PAYMENT_TYPE_PREPAID = 'prepaid';
export const PAYMENT_TYPE_POSTPAID = 'postpaid';

export const exportFormats = [
  {
    code: 'XLSX',
    label: 'Microsoft Excel Spreadsheet (XLSX)'
  },
  {
    code: 'CSV',
    label: 'Comma Separated Values (CSV)'
  },
]

export default class Shipment extends Resource {
  static name = 'shipment'
  static icon = 'bxs-truck'

  static get defaultTableState() {
    return {
      ...super.defaultTableState,
      sortBy: "updated_at",
      sortDesc: true,
    }
  }

  static fields = [
    { 
      key: 'external_reference_id', 
      sortable: true,
      label: 'shipments.external-reference'
    },
    nameField('last_name'),
    {
      key: 'status',
      label: 'common.status',
      sortable: true,
    },
    { 
      key: 'reference_id', 
      sortable: false,
      label: 'shipments.reference_id',
    },
    {
      key: 'type',
      label: 'shipments.type',
      sortable: true,
      formatter: (value) => i18n.t(`shipments.types.${value}`)
    },
    { 
      key: 'shipments:package_count', 
      label: 'packages.title',
    },
    { 
      key: 'shipments:pickup_location', 
      sortable: true,
      sortKey: 'pickup_location_id',
      label: 'locations.types.pickup',
    },
    { 
      key: 'shipments:dropoff_location', 
      sortable: true,
      sortKey: 'dropoff_location_id',
      label: 'locations.types.dropoff',
    },
    { 
      key: 'created_at', 
      sortable: true,
      label: 'common.created_at',
      formatter: (value) => Vue.filter('moment')(value)
    },
    { 
      key: 'updated_at', 
      sortable: true,
      label: 'common.updated_at',
      formatter: (value) => Vue.filter('moment')(value)
    },
    { 
      key: 'created_by_name', 
      sortable: true,
      sortKey: 'created_at',
      label: 'shipments.created_by',
    },
    { 
      key: 'locale', 
      label: 'common.locale',
      formatter: v => i18n.t(`locale.${v}`)
    },
    merchantField(),
  ]

  static filters = [
    {
      type: 'date',
      name: 'created_at',
      label: 'common.created_at',
    },
    {
      type: 'date',
      name: 'updated_at',
      label: 'common.updated_at',
    },
    {
      type: 'select',
      name: 'type',
      label: 'shipments.type',
      options: this.types,
    },
    {
      type: 'select',
      name: 'status',
      label: 'shipments.status',
      options: this.statuses.filter(s => !s.hidden),
      multiple:  true,
    },
    {
      type: 'relation',
      name: 'createdBy',
      label: 'shipments.created_by',
      resource: 'users'
    },
    {
      type: 'relation',
      name: 'pickupLocation',
      label: 'shipments.pickup-location',
      resource: 'locations',
      scope: 'pickup',
    },
    {
      type: 'relation',
      name: 'dropoffLocation',
      label: 'shipments.dropoff-location',
      resource: 'locations',
      scope: 'dropoff',
    },
    {
      type: 'country',
      name: 'country',
      label: 'common.country',
    },
    merchantFilter(),
    {
      $feature: FEATURE_BATCHES,
      type: 'relation',
      name: 'batch',
      label: 'batches.label',
      resource: 'batches'
    },
    {
      $feature: FEATURE_MANIFESTS,
      type: 'relation',
      name: 'manifest',
      label: 'manifests.label',
      resource: 'manifests'
    },
    {
      name: 'rerouted',
      label: 'packages.rerouted',
      type: 'boolean',
      default: true,
    },
    {
      name: 'consolidated',
      label: 'packages.consolidated',
      type: 'boolean',
      default: false,
      flag: true,
    },
    {
      name: 'missing',
      label: 'packages.missing',
      type: 'boolean',
      default: true,
    },
    {
      $feature: FEATURE_POSTPAYMENTS,
      type: 'select',
      name: 'payment-type',
      label: 'shipments.payment-type',
      options: this.paymentTypes,
    },
    {
      $feature: FEATURE_POSTPAYMENTS,
      name: 'paid',
      label: 'shipments.paid',
      type: 'boolean',
      default: true,
    }
  ]

  static actions = [
    {
      name: 'register',
      icon: 'bx-printer',
      variant: 'info',
      async action(item) {
        await item.$register()
        await item.$print()

        return {refresh: true}
      }
    },
    {
      name: 'print',
      icon: 'bx-printer',
      async action(item) {
        await item.$print()
        return {refresh: true}
      }
    },
    {
      name: 'private-deliver',
      icon: 'bxs-car',
      variant: 'info',
      async action(item) {
        console.log('private-deliver',item)
        return {refresh: false}
      }
    },
    {
      name: 'request-location',
      icon: 'bx-location-plus',
      variant: 'info',
      confirm: true,
      confirmText: 'shipments.actions.request-location-confirm-text',
      confirmOk: 'shipments.actions.request-location-confirm-ok',
      async action(item) {
        await item.$requestLocation()
        return {refresh: true}
      }
    },
    {
      name: 'request-pickup',
      icon: 'bxs-truck',
      variant: 'primary',
      async action(item) {
        await item.$requestPickup()
        return {refresh: true}
      }
    }
  ]

  static batchActions = [
    {
      name: 'print',
      icon: 'bx-printer',
      label: 'shipments.batch-actions.print',
      or: 'register',
      modal: BatchPrintModal,
    },
    {
      name: 'request-pickup',
      icon: 'bxs-truck',
      label: 'shipments.batch-actions.request-pickup',
      async action(item) {
        return await item.$requestPickup()
      }
    },
    {
      name: 'export',
      icon: 'bx-export',
      label: 'shipments.batch-actions.export',
      modal: Export,
    },
    deleteBatchAction(),
  ]

  static relations = [
    {
      name: 'packages',
      resource: 'packages'
    }
  ]

  get badges() {
    const badges = []

    if(this.is_rerouted) {
      badges.push({
        label: 'packages.rerouted',
        variant: 'warning',
        icon: 'bx-git-pull-request',
      })
    }

    if(this.is_consolidated) {
      badges.push({
        label: 'packages.consolidated',
        variant: 'warning',
        icon: 'bx-unite',
      })
    }

    if(this.missing) {
      badges.push({
        label: 'packages.missing',
        variant: 'danger',
        icon: 'bx-low-vision',
      })
    }

    if(this.is_unpaid) {
      badges.push({
        label: 'shipments.unpaid',
        variant: 'dark',
        icon: 'bx-coin',
      })
    }

    return badges
  }

  static extraRoutes({prefix}) {
    return [
      {
        name: `shipments-import`,
        path: `${prefix}/shipments/import`,
        component: () => import(/* webpackChunkName: "shipments-Import" */ `@/pages/shipments/Import`),
        redirect: {name: 'shipments-import-upload'},
        children: [
          {
            name: 'shipments-import-upload',
            path: `${prefix}/shipments/import/upload`,
            component: () => import(/* webpackChunkName: "shipments-Import" */ `@/pages/shipments/Import/Upload`),
          },
          {
            name: 'shipments-import-preview',
            path: `${prefix}/shipments/import/preview`,
            component: () => import(/* webpackChunkName: "shipments-Import" */ `@/pages/shipments/Import/Preview`),
            props: true
          },
          {
            name: 'shipments-import-save',
            path: `${prefix}/shipments/import/save`,
            component: () => import(/* webpackChunkName: "shipments-Import" */ `@/pages/shipments/Import/Save`),
            props: true
          },
        ]
      },
    ]
  } 

  async $register() {
    await api.post(`shipments/${this.id}/register`)

    return true
  }

  async $print() {
    let {data} = await api.post(`shipments/${this.id}/print`,undefined,{ 
      responseType: 'blob',
    })

    printer.$emit('shipment:print',new Blob([data], {type: 'application/pdf'}))

    return true
  }

  async $requestPickup() {
    await api.post(`shipments/${this.id}/request-pickup`)

    return true
  }

  async $requestLocation() {
    await api.post(`shipments/${this.id}/request-location`)

    return true
  }

  async $process() {
    await api.post(`shipments/${this.id}/process`)
  }

  static async bulkPrint(ids) {
    let {data} = await api.get(`shipments/bulk-print`,{
      params:{ids},
      responseType: 'blob',
    })

    printer.$emit('shipment:print',new Blob([data], {type: 'application/pdf'}))

    return true
  }

  static async export(ids,format = 'XLSX') {
    let response = await api.get('shipments/export',{
      params: {ids,format},
      responseType: 'blob'
    })

    download(response)

    return true
  }

  get defaults() {
    const user = store.getters['auth/user']
    const features = store.getters['auth/features']
    const preferences = store.getters['auth/preferences']

    return {
      type: 'standard',
      country: user.country || 'BS',
      locale: user.locale || 'en',
      merchant_id: user.merchant_id || null,
      pickup_location_id: user.preferences?.default_pickup || null,
      contents: features & FEATURE_CONTENT ? preferences?.default_content_type || null : null
    }
  }

  get name() {
    return `${this.first_name} ${this.last_name}`
  }

  static get types() {
    return [
      { label: `shipments.types.${TYPE_STANDARD}`, code: TYPE_STANDARD },
      { label: `shipments.types.${TYPE_EXPRESS}`, code: TYPE_EXPRESS },
    ]
  }

  static get paymentTypes() {
    return [
      { label: 'shipments.payment-types.prepaid', code: 'prepaid' },
      { label: 'shipments.payment-types.postpaid', code: 'postpaid' },
    ]
  }

  static get statuses() {
    return [
      { label: `shipments.statuses.${STATUS_DRAFT}`, code: STATUS_DRAFT, icon: 'bx-file-blank' },
      { label: `shipments.statuses.${STATUS_REQUESTING_CUSTOMER_INPUT}`, code: STATUS_REQUESTING_CUSTOMER_INPUT, icon: 'bx-message-detail', hidden: true },
      { label: `shipments.statuses.${STATUS_AWAITING_CUSTOMER_INPUT}`, code: STATUS_AWAITING_CUSTOMER_INPUT, icon: 'bx-pencil' },
      { label: `shipments.statuses.${STATUS_REGISTRABLE}`, code: STATUS_REGISTRABLE, icon: 'bx-list-check' },
      { label: `shipments.statuses.${STATUS_PARTLY_REGISTERED}`, code: STATUS_PARTLY_REGISTERED, variant: 'warning', icon: 'bx-help-circle', hidden: true },
      { label: `shipments.statuses.${STATUS_REGISTERED}`, code: STATUS_REGISTERED, timeline: true, icon: 'bx-printer' },
      { label: `shipments.statuses.${STATUS_AWAITING_PICKUP}`, code: STATUS_AWAITING_PICKUP, timeline: true, icon: 'bx-upload' },
      { label: `shipments.statuses.${STATUS_PICKED_UP}`, code: STATUS_PICKED_UP, timeline: true, icon: 'bxs-truck' },
      { label: `shipments.statuses.${STATUS_IN_WAREHOUSE}`, code: STATUS_IN_WAREHOUSE, icon: 'bxs-building' },
      { label: `shipments.statuses.${STATUS_DROPPED_OFF}`, code: STATUS_DROPPED_OFF, timeline: true, icon: 'bx-download' },
      { label: `shipments.statuses.${STATUS_AWAITING_PAYMENT}`, code: STATUS_AWAITING_PAYMENT, icon: 'bx-coin', $feature: FEATURE_POSTPAYMENTS },
      { label: `shipments.statuses.${STATUS_READY_TO_COLLECT}`, code: STATUS_READY_TO_COLLECT, timeline: true, icon: 'bx-user-check' },
      { label: `shipments.statuses.${STATUS_COLLECTED}`, code: STATUS_COLLECTED, timeline: true, icon: 'bx-check-circle' },
      
      { label: `shipments.statuses.${STATUS_CANCELLED}`, code: STATUS_CANCELLED, variant: 'danger', icon: 'bx-x-circle' },
      { label: `shipments.statuses.${STATUS_RETURNED}`, code: STATUS_RETURNED, variant: 'danger', icon: 'bx-undo' },

      { label: `shipments.statuses.${STATUS_EXPIRED}`, code: STATUS_EXPIRED, variant: 'warning', icon: 'bx-alarm-exclamation' },
      { label: `shipments.statuses.${STATUS_EXPIRED_COLLECTABLE}`, code: STATUS_EXPIRED_COLLECTABLE, variant: 'danger', icon: 'bx-calendar-x' },
      { label: `shipments.statuses.${STATUS_EXPIRED_COLLECTED}`, code: STATUS_EXPIRED_COLLECTED, variant: 'danger', icon: 'bx-check-circle' },
      
      { label: `shipments.statuses.${STATUS_LOST}`, code: STATUS_LOST, variant: 'danger', icon: 'bx-ghost' },
      
    ]
  }
}

Resource.registerResource(Shipment)