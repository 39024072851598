// import i18n from "@/i18n";
import api from "@/api";
import router from "@/router";
import Resource from "@/services/Resource";
import store from "@/store";
import Vue from "vue";
import Batch from "./Batch";
import { deleteBatchAction, nameField } from "./common";
import { FEATURE_BATCH_CREDIT_CARDS } from "./Merchant";
import Shipment from "./Shipment";

export const STATUS_FAILED           = -50;
export const STATUS_DRAFT            = 0;
export const STATUS_PENDING          = 25;
export const STATUS_NOTIFIED         = 50;
export const STATUS_REMINDED         = 70;
export const STATUS_IDLE             = 80;
export const STATUS_RESPONDED        = 90;
export const STATUS_SHIPMENT_CREATED = 100;

export default class BatchItem extends Resource {
  static name = 'batch_item'
  static icon = 'bxs-user-detail'

  static parent = Batch

  static routePrefix = '/batches/:parent_id'

  static fields = [
    nameField('last_name'),
    {
      key: 'status',
      label: 'common.status',
      sortable: true,
    },
    { 
      $feature: FEATURE_BATCH_CREDIT_CARDS,
      key: 'offset_date', 
      sortable: true,
      label: 'batch_items.expiry-date',
      formatter: (value) => value ? Vue.filter('moment')(value,'LL') : null
    },
    { 
      key: 'email', 
      label: 'common.email',
    },
    // { 
    //   key: 'locale', 
    //   label: 'common.locale',
    //   formatter: v => i18n.t(`locale.${v}`)
    // },
    { 
      key: 'phone', 
      label: 'common.phone',
      formatter: (v,k,{full_phone_number}) => `+${full_phone_number}`
    },
    { 
      key: 'reminders_sent', 
      label: 'batch_items.reminders-sent',
    },
  ]

  static actions = [
    {
      name: 'notify',
      icon: 'bx-message-detail',
      // variant: 'info',
      async action(item) {
        await item.$notify()

        return {refresh: true}
      }
    },
    {
      name: 'restart',
      icon: 'bx-reset',
      variant: 'info',
      async action(item) {
        await item.$restart()

        return {refresh: true}
      }
    },
    {
      name: 'convert',
      icon: 'bxs-truck',
      variant: 'info',
      async action(item) {
        await item.$convert()

        return {refresh: true}
      }
    },
  ]

  static batchActions = [
    {
      name: 'notify',
      icon: 'bx-message-detail',
      label: 'batch_items.batch-actions.notify',
      async action(item) {
        await item.$notify()

        return {refresh: true}
      }
    },
    {
      name: 'convert',
      icon: 'bxs-truck',
      label: 'batch_items.batch-actions.convert',
      async action(item) {
        await item.$convert()

        return {refresh: true}
      }
    },
    deleteBatchAction()
  ]

  static filters = [
    {
      $feature: FEATURE_BATCH_CREDIT_CARDS,
      name: 'offset_date',
      label: 'batch_items.expiry-date',
      type: 'date',
    },
    {
      name: 'status',
      label: 'common.status',
      type: 'select',
      options: this.statuses,
      multiple:  true,
    },
  ]

  get defaults() {
    const user = store.getters['auth/user']

    return {
      country: user.country || 'BS'
    }
  }

  get name() {
    return `${this.first_name} ${this.last_name}`
  }

  static get types() {
    return Shipment.types
  }

  $open() {
    router.push({name: `${this.constructor.plural}-show`, params: { id: this.id, parent_id: this.batch_id }})
  }

  async $notify() {
    await api.post(`batch_items/${this.id}/notify`)

    return true
  }

  async $restart() {
    await api.post(`batch_items/${this.id}/restart`)

    return true
  }

  async $convert() {
    await api.post(`batch_items/${this.id}/convert`)

    return true
  }

  static get statuses() {
    return [
      { label: `batch_items.statuses.${STATUS_FAILED}`, code: STATUS_FAILED, icon: 'bx-error', variant: 'danger' },
      { label: `batch_items.statuses.${STATUS_DRAFT}`, code: STATUS_DRAFT, icon: 'bx-file-blank'},
      { label: `batch_items.statuses.${STATUS_PENDING}`, code: STATUS_PENDING, icon: 'bx-time-five' },
      { label: `batch_items.statuses.${STATUS_NOTIFIED}`, code: STATUS_NOTIFIED, icon: 'bx-message-detail' },
      { label: `batch_items.statuses.${STATUS_REMINDED}`, code: STATUS_REMINDED, icon: 'bx-message-error' },
      { label: `batch_items.statuses.${STATUS_IDLE}`, code: STATUS_IDLE, icon: 'bx-message-x', variant: 'warning' },
      { label: `batch_items.statuses.${STATUS_RESPONDED}`, code: STATUS_RESPONDED, icon: 'bx-list-check' },
      { label: `batch_items.statuses.${STATUS_SHIPMENT_CREATED}`, code: STATUS_SHIPMENT_CREATED, icon: 'bx-check-circle' },
    ]
  }
}

Resource.registerResource(BatchItem)