import i18n from "@/i18n";
import Resource from "@/services/Resource";
import Vue from "vue";
import { deleteBatchAction, merchantFilter, nameField } from "./common";

export const STATUS_DRAFT     = 0
export const STATUS_FINALIZED = 10
export const STATUS_LIVE = 50;
export const STATUS_FULFILLED = 100;

export const MODE_ROAD     = 'road'
export const MODE_MARITIME = 'maritime'
export const MODE_RAIL     = 'rail'
export const MODE_AIR      = 'air'

export default class Manifest extends Resource {
  static name = 'manifest'
  static icon = 'bx-receipt'

  static fields = [
    nameField(),
    {
      key: 'status',
      label: 'common.status',
      sortable: true,
    },
    {
      key: 'delivery_attempted',
      label: 'manifests.delivery_attempted',
      sortable: true,
    },
    {
      key: 'mode',
      label: 'manifests.mode',
      sortable: true,
      formatter: (value) => i18n.t(`manifests.modes.${value}`)
    },
    { 
      key: 'shipment_count', 
      sortable: false,
      label: 'shipments.title',
    },
    { 
      key: 'package_count', 
      sortable: false,
      label: 'packages.title',
    },
    { 
      key: 'created_at', 
      sortable: true,
      label: 'common.created_at',
      formatter: (value) => Vue.filter('moment')(value)
    },
  ]

  static filters = [
    {
      type: 'date',
      name: 'created_at',
      label: 'common.created_at',
    },
    {
      type: 'select',
      name: 'mode',
      label: 'manifests.mode',
      options: this.modes,
    },
    {
      type: 'select',
      name: 'status',
      label: 'shipments.status',
      options: this.statuses.filter(s => !s.hidden),
      multiple:  true,
    },
    merchantFilter(),
  ]

  static batchActions = [
    deleteBatchAction()
  ]

  static relations = [
    {
      name: 'packages',
      resource: 'packages'
    }
  ]

  static get statuses() {
    return [
      { label: `manifests.statuses.${STATUS_DRAFT}`, code: STATUS_DRAFT, icon: 'bx-file-blank' },
      { label: `manifests.statuses.${STATUS_FINALIZED}`, code: STATUS_FINALIZED, icon: 'bx-list-check' },
      { label: `manifests.statuses.${STATUS_LIVE}`, code: STATUS_LIVE, icon: 'bxs-truck' },
      { label: `manifests.statuses.${STATUS_FULFILLED}`, code: STATUS_FULFILLED, icon: 'bx-check-circle' },
    ]
  }

  static get modes() {
    return [
      { label: `manifests.modes.${MODE_AIR}`, code: MODE_AIR, icon: 'bxs-plane-alt' },
      { label: `manifests.modes.${MODE_MARITIME}`, code: MODE_MARITIME, icon: 'bxs-ship' },
      { label: `manifests.modes.${MODE_RAIL}`, code: MODE_RAIL, icon: 'bxs-train' },
      { label: `manifests.modes.${MODE_ROAD}`, code: MODE_RAIL, icon: 'bxs-truck' },
    ]
  }
}

Resource.registerResource(Manifest)