import Vue from 'vue'
import { transMap, capitalize, ucfirst } from './utils';
import i18n from './i18n'
import moment from 'moment';
import { featureFilter, roleFilter } from './resources/User';

Vue.filter('moment', (date,format = 'LLL') => {
  return moment(date).locale(i18n.locale).format(format);
})
Vue.filter('age', (date) => {
  return moment(date).locale(i18n.locale).fromNow();
})
Vue.filter('transMap', (arr, first = 'label', ...rest) => transMap(arr,first,...rest))
Vue.filter('capitalize', capitalize)
Vue.filter('ucfirst', ucfirst)
Vue.filter('featureFilter', arr => arr.filter(featureFilter))
Vue.filter('roleFilter', arr => arr.filter(roleFilter))