/*eslint no-empty-pattern: "off"*/
import api from '@/api';
import { ROLE_CUSTOMER_SUPPORT, ROLE_DRIVER, ROLE_MERCHANT_ADMIN, ROLE_MERCHANT_USER, ROLE_PUDO, ROLE_SUPERADMIN, ROLE_WAREHOUSE } from '@/resources/User';
const state = {
  user: null,
  preferences: {},
};

const getters = {
  user             : state => state.user,
  preferences      : state => state.preferences,
  isAuthenticated  : state => !!state.user,
  role             : state => state.user?.role || 0,
  features         : state => (state.user?.features || 0),
  isSuperAdmin     : state => Boolean(state.user?.role & ROLE_SUPERADMIN),
  isCustomerSupport: state => Boolean(state.user?.role & ROLE_CUSTOMER_SUPPORT),
  isMerchant       : state => Boolean(state.user?.role & (ROLE_MERCHANT_ADMIN | ROLE_MERCHANT_USER)),
  isMerchantAdmin  : state => Boolean(state.user?.role & ROLE_MERCHANT_ADMIN),
  isPUDO           : state => Boolean(state.user?.role & ROLE_PUDO),
  isWarehouse      : state => Boolean(state.user?.role & ROLE_WAREHOUSE),
  isDriver         : state => Boolean(state.user?.role & ROLE_DRIVER),
  hasFeature       : (state,getters) => feature => Boolean(getters.isSuperAdmin || state.user.features & feature)
};

const actions = {
  async signIn({ dispatch }, data) 
  {
    await api.get('../sanctum/csrf-cookie')
    let response = await api.post('../login', data,{
      suppress: [422]
    })
    dispatch('setUser', response.data.user)
    return response
  },
  async signOut({ dispatch }) 
  {
    let response = await api.post('../logout')
    dispatch('setUser', null)
    return response
  },
  async forgotPassword({},data) 
  {
    await api.get('../sanctum/csrf-cookie')
    await api.post('../forgot-password', data)
  },
  async resetPassword({},data) 
  {
    await api.get('../sanctum/csrf-cookie')
    await api.post('../reset-password', data)
  },
  setUser({ commit, dispatch },user) 
  {
    commit('setUser',user)
    if(user?.locale) {
      commit('setLocale',user.locale,{ root: true })
    }
    dispatch('fetchPreferences')
  },
  async fetchPreferences({commit, getters}) 
  {
    if(getters.isMerchant) {
      let {data: {data}} = await api.get('merchants/preferences')
      commit('setPreferences',data)
    } else {
      commit('setPreferences',{})
    }
  }
};

const mutations = {
  setUser(state, user) {
    state.user = user || null
  },
  setPreferences(state, preferences) {
    state.preferences = preferences
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};