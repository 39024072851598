<template>
  <b-modal 
    centered
    ref="modal"
    v-if="item"
  >
    <template #modal-title>
      {{$t('driver.reroute.title')}} {{item.barcode}}
    </template>

    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90%;"
    >

      <b-button 
        @click="reroute(location)" 
        v-for="(location,i) in locations" 
        :key="location.id"
        class="w-100 d-block mb-2 py-3 text-left"
      >
        <b-badge variant="info" class="float-right mt-1">{{location.distance | distance}} km</b-badge>
        <b-badge v-if="!i" variant="success" class="float-right mt-1 mr-2">{{$t('driver.reroute.closest')}}</b-badge>
        <b-badge v-if="location.class==='pudo'" variant="light" class="float-right mt-1 mr-2">{{$t('pudo.title')}}</b-badge>
        {{location.name}}
      </b-button>

      <em>{{$t('driver.distance-info',{location:item.dropoff_location_name})}}</em>

    </b-overlay>

    <template #modal-footer>
      <b-button @click="close(null)">{{$t('common.cancel')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import Package from '@/resources/Package'
import api from '@/api'
// import DriverModal from '@/components/Modals/Driver.vue'

export default {
  name: 'Reroute',
  extends: Modal,
  components: {
  },
  props: {
    item: {
      type: Package,
      required: true,
    },
  },
  filters: {
    distance(value) {
      return value.toFixed(2)
    }
  },
  data() {
    return {
      loading: true,
      selectedLocation: null,
      locations: [],
    }
  },
  computed: {
  },
  created() {
    this.fetchLocations()
  },
  methods: {
    async fetchLocations() {
      let {data: {data: locations}} = await api.get(`packages/${this.item.id}/alternative-locations`,{
        params: {
          barcode: this.barcode
        }, 
      })

      this.locations = locations
      this.loading = false
    },
    async reroute(location) {
      if(location.class === 'pudo') {
        await this.$bvModal.msgBoxOk(this.$t('driver.reroute.pudo-reroute-info'))
        this.close(true)
        return
      }

      this.loading = true

      let confirm = true

      confirm = await this.$bvModal.msgBoxConfirm(this.$t('driver.actions.reroute-confirm',{
        location:location.name
      }),{
        cancelTitle: this.$t('common.cancel'),
        okTitle: this.$t('driver.actions.reroute'),
        okVariant: 'danger'
      })

      if(confirm) {
        try {
          await this.item.$reroute(location.id)

          this.close(true)
        } finally {
          this.loading = false
        }

      } else {
        this.close()
      }
    },
  }
}
</script>