<template>
  <v-select
    v-model="internalValue"
    :deselect-from-dropdown="multiple"
    v-bind="{options, clearable, required, disabled, multiple, selectable, placeholder, reduce}"
    v-on="{...$listeners,input:undefined}"
    label="label"
    appendToBody
  >
    <template #no-options>{{$t('ui.select-no-results')}}</template>
    <template #option="{label, meta}">
      <div v-if="meta" style="font-style: italic">{{label}}</div>
      <div v-else>{{label}}</div>
    </template>
  </v-select>
</template>

<script>
import Resource from "@/services/Resource";

export default {
  props: {
    resourceName: {
      type: String,
      required: true,
    },
    scope: {
      type: [String,Array],
      default: null
    },
    refreshOn: {
      type: Number,
      default: null
    },
    value: {
      type: [Number,Array,String],
      default: null,
    },
    placeholder: {
      type: [String,Number],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledValue: {
      type: Object,
      default() {
        return {
          code: this.value,
          label: this.value ? 'n/a' : ''
        }
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Function,
      default: undefined
    },
    metaOptions: {
      type: Array,
      default: Array
    },
    reduce: {
      type: Function,
      default: opt => opt.code
    }
  },
  data() {
    return {
      resource: Resource.getResource(this.resourceName),
      options: [],
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  created() {
    if(this.disabled) {
      this.options = [this.disabledValue]
    } else {
      this.fetchItems()
    }
  },
  watch: {
    disabled(isDisabled) {
      if(!isDisabled) {
        this.fetchItems()
      }
    },
    refreshOn() {
      if(this.internalValue) {
        this.internalValue = null
      }
      this.fetchItems()
    }
  },
  methods: {
    async fetchItems() {
      try {
        const options = await this.resource.list({ light: true, scope: this.scope })
        this.options = [
          ...options,
          ...this.metaOptions.map(o => ({...o,meta: true}))
        ];

        if(!this.multiple && options.length === 1) {
          this.internalValue = this.reduce(options[0])
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>