import access from "./access";
import { FEATURE_BATCHES, FEATURE_CONTENT, FEATURE_MANIFESTS } from "./resources/Merchant";

export default [
  {
    $roles: access['dashboard'],
    route: 'dashboard',
    icon: 'bxs-dashboard',
    title: 'dashboard.title'
  },
  {
    $roles: access['shipments'],
    route: 'shipments',
    icon: 'bxs-truck',
    title: 'shipments.title'
  },
  {
    $feature: FEATURE_MANIFESTS,
    $roles: access['manifests'],
    route: 'manifests',
    icon: 'bxs-receipt',
    title: 'manifests.title'
  },
  {
    $feature: FEATURE_BATCHES,
    route: 'batches',
    icon: 'bxs-component',
    title: 'batches.title'
  },
  {
    $roles: access['locations'],
    route: 'locations',
    icon: 'bxs-map',
    title: 'locations.title'
  },
  {
    $roles: access['location_groups'],
    route: 'location_groups',
    icon: 'bxs-edit-location',
    title: 'location_groups.title'
  },
  {
    $roles: access['merchants'],
    route: 'merchants',
    icon: 'bxs-cart',
    title: 'merchants.title'
  },
  {
    $roles: access['users'],
    route: 'users',
    icon: 'bxs-group',
    title: 'users.title'
  },
  {
    $roles: access['merchants-preferences'],
    $feature: FEATURE_CONTENT,
    route: 'merchants-preferences',
    icon: 'bxs-cog',
    title: 'merchants.preferences.title'
  },
  {
    $roles: access['pudo'],
    route: 'pudo',
    icon: 'bxs-store',
    title: 'pudo.title'
  },
  {
    $roles: access['warehouse'],
    route: 'warehouse',
    icon: 'bxs-building',
    title: 'warehouse.title'
  },
  {
    $roles: access['lockers'],
    route: 'lockers',
    icon: 'bx-grid',
    title: 'lockers.title'
  },
  {
    $roles: access['driver'],
    route: 'driver',
    icon: 'bxs-truck bx-flip-horizontal',
    title: 'driver.title'
  },
]