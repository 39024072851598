import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import routes from './routes'
import access from '@/access'
import { ROLE_DRIVER, ROLE_PUDO, ROLE_SUPERADMIN, ROLE_WAREHOUSE } from '@/resources/User'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = store.getters['auth/isAuthenticated']
  let currentRole = store.getters['auth/role']

  let startRoute = ((currentRole) => {
    if(currentRole & ROLE_SUPERADMIN) return 'dashboard'
    if(currentRole & ROLE_PUDO) return 'pudo'
    if(currentRole & ROLE_WAREHOUSE) return 'warehouse'
    if(currentRole & ROLE_DRIVER) return 'driver'
    else return 'dashboard'
  })(currentRole)

  // console.log({
  //   isAuthenticated,
  //   currentRole,
  //   access,
  //   guest: to.meta.guest,
  //   name: to.name,
  //   user: user?.id,
  // },user?.id)

  // Not logged in
  if (!to.meta.guest && !isAuthenticated) next({ name: 'signin' })
  // Logged in on signin page
  else if(to.name == 'signin' && isAuthenticated) next({ name: startRoute })
  // Missing access config or access denied
  else if(!to.meta.guest && !accessCheck(to.name)) next({ name: startRoute })
  // All good
  else next()
})

export const accessCheck = route => !!(route in access && (access[route] & store.getters['auth/role']))

export default router