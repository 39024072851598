<template>
  <div class="nova-layout">
    <Header @toggle="expanded = $event" :class="headerClass" />
    <Navigation :class="{expanded}" />
    <main>
      <div :class="[fluid ? 'container-fluid' : 'container', 'p-4']">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import Navigation from '@/components/Layout/Navigation.vue'
import Header from '@/components/Layout/Header.vue'

export default {
  name: 'MainLayout',
  components: { 
    Navigation,
    Header,
  },
  data() {
    return {
      expanded: false
    }
  },
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
      default: null,
    }
  },
};
</script>