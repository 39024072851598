import { setupResourceStore } from '@/utils'
import LocationGroup from '@/resources/LocationGroup';

const resource = setupResourceStore(LocationGroup)


const state = {
  ...resource.state
};

const getters = {
  ...resource.getters
};

const actions = {
  ...resource.actions
};

const mutations = {
  ...resource.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};