<template>
  <nav>
    <ul class="menu">
      <li v-for="entry in menu" :key="entry.route">
        <router-link :to="{name: entry.route}">
          <i :class="['bx',entry.icon]" ></i>
          <span class="link-name">{{ $t(entry.title) }}</span>
        </router-link>
        <!-- <span class="tooltip">{{ $t(entry.title) }}</span> -->
      </li>
    </ul>

    <div v-if="user" class="profile">
      <router-link :to="{name:'users-show',params: {id: user.id}}">
        <i class="bx bxs-user-rectangle bx-sm bx-tada-hover text-primary"></i>
      </router-link>
      <div class="name-role">
        <div class="name" v-text="`${user.first_name} ${user.last_name}`" />
        <div class="role" v-text="$t(role)" />
      </div>
      <i class="bx bx-log-out bx-sm linkish" @click="doSignOut"></i>
    </div>
  </nav>
</template>

<script>
import menu from '@/menu'
import { featureFilter, roleFilter, roleMap } from '@/resources/User'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      menu: menu.filter(roleFilter).filter(featureFilter),
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    role() {
      return roleMap[this.user.role & -this.user.role]
    }
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut'
    }),
    async doSignOut() {
      await this.signOut()
      this.$router.push({name: 'signin'});
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/css/variables.scss';

  ul {
    margin: .75rem;

    li {
      position   : relative;
      height     : 50px;
      width      : 100%;
      margin     : 0 5px;
      list-style : none;
      line-height: 50px;
      margin     : 5px 0;

      .tooltip {
        position      : absolute;
        left          : 125px;
        top           : 0;
        transform     : translate(-50%, -50%);
        border-radius : 6px;
        height        : 35px;
        width         : 120px;
        background    : $dark;
        color         : #fff;
        line-height   : 35px;
        text-align    : center;
        box-shadow    : 0 5px 10px rgba(0, 0, 0, 0.2);
        transition    : 0s;
        opacity       : 0;
        pointer-events: none;
        display       : block;
      }

      &:hover .tooltip {
        transition: all 0.5s ease;
        opacity   : 1;
        top       : 50%
      }

      a {
        color          : $light;
        display        : flex;
        align-items    : center;
        text-decoration: none;
        border-radius  : 12px;
        white-space    : nowrap;
        transition     : all 0.4s ease;

        &:hover {
          color     : $dark;
          background: $primary;
        }
      }

      i {
        font-size    : 18px;
        font-weight  : 400;
        height       : 50px;
        min-width    : 50px;
        border-radius: 12px;
        line-height  : 50px;
        text-align   : center;
      }

      .link-name {
        font-size     : 15px;
        font-weight   : 400;
        opacity       : 1;
        pointer-events: auto;
      }
    }
  }

  .profile {
    background-color: $dark;
    display       : flex;
    align-items   : center;
    justify-content: space-between;
    white-space   : nowrap;
    padding: .5rem;

    .name-role {
      padding-left: .75rem;
      flex-grow: 1;

      .name {
        font-size  : 15px;
        font-weight: 400;
      }
      .role {
        font-size: 12px;
      }
    }
  }
</style>