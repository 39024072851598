import api from "@/api";
import i18n from "@/i18n";
import Resource from "@/services/Resource";
import store from "@/store";
import Vue from "vue";
import { deleteBatchAction, merchantField, merchantFilter, nameField } from "./common";
import { BATCH_FEATURES, FEATURE_CONTENT } from "./Merchant";
import Shipment from "./Shipment";

export const STATUS_DRAFT     = 0;
export const STATUS_PAUSED    = 25;
export const STATUS_RUNNING   = 50;
export const STATUS_COMPLETED = 100;

export const CHANNEL_EMAIL = 1;
export const CHANNEL_SMS  = 2;

export default class Batch extends Resource {
  static name = 'batch'
  static icon = 'bxs-component'

  static relations = [
    {
      name: 'items',
      resource: 'batch_items'
    }
  ]

  static fields = [
    nameField(),
    {
      key: 'status',
      label: 'common.status',
      sortable: true,
      formatter: (value) => i18n.t(`batches.statuses.${value}`)
    },
    {
      key: 'type',
      label: 'shipments.type',
      formatter: (value) => i18n.t(`shipments.types.${value}`)
    },
    {
      key: 'item_count',
      label: 'batches.customers'
    },
    { 
      key: 'shipments:pickup_location', 
      sortable: true,
      sortKey: 'pickup_location_id',
      label: 'locations.types.pickup',
    },
    { 
      key: 'created_at', 
      sortable: true,
      label: 'common.created_at',
      formatter: (value) => Vue.filter('moment')(value)
    },
    merchantField(),
  ]

  static filters = [
    {
      name: 'date',
      label: 'common.date',
      type: 'date',
    },
    // {
    //   name: 'type',
    //   label: 'shipments.type',
    //   type: 'select',
    //   options: this.types,
    // },
    {
      name: 'status',
      label: 'common.status',
      type: 'select',
      options: this.statuses,
      multiple:  true,
    },
    merchantFilter()
  ]

  static actions = [
    {
      name: 'start',
      icon: 'bx-play-circle',
      variant: 'info',
      async action(item) {
        await item.$start()

        return {refresh: true}
      }
    },
    {
      name: 'pause',
      icon: 'bx-pause-circle',
      async action(item) {
        await item.$pause()

        return {refresh: true}
      }
    },
  ]

  static batchActions = [
    deleteBatchAction()
  ]

  get defaults() {
    const user = store.getters['auth/user']
    const features = store.getters['auth/features']
    const creditCards = features & (BATCH_FEATURES)
    const preferences = store.getters['auth/preferences']

    const settings = creditCards ? {
      offset: 30,
      reminder_count: 4,
      reminder_interval: 7,
      custom_final_reminder: false,
      channels: CHANNEL_EMAIL | CHANNEL_SMS,
    } : {
      reminder_count: 0,
      reminder_interval: 7,
      custom_final_reminder: false,
      channels: 0,
    }

    return {
      type: 'standard',
      merchant_id: user.merchant_id || null,
      pickup_location_id: user.preferences?.default_pickup || null,
      features: features & (BATCH_FEATURES),
      packages_small: creditCards ? 1 : 0,
      packages_medium: 0,
      packages_large: 0,
      packages_oversized: 0,
      contents: features & FEATURE_CONTENT ? preferences?.default_content_type || null : null,
      settings
    }
  }

  static get types() {
    return Shipment.types
  }

  static get statuses() {
    return [
      { label: `batches.statuses.${STATUS_DRAFT}`, code: STATUS_DRAFT, icon: 'bx-file-blank' },
      { label: `batches.statuses.${STATUS_PAUSED}`, code: STATUS_PAUSED, icon: 'bx-pause-circle' },
      { label: `batches.statuses.${STATUS_RUNNING}`, code: STATUS_RUNNING, icon: 'bx-play-circle bx-flashing' },
      { label: `batches.statuses.${STATUS_COMPLETED}`, code: STATUS_COMPLETED, icon: 'bx-check-circle' },
    ]
  }

  static get channels() {
    return [
      { label: `batches.channels.${CHANNEL_EMAIL}`, code: CHANNEL_EMAIL, icon: 'bx-envelope' },
      { label: `batches.channels.${CHANNEL_SMS}`, code: CHANNEL_SMS, icon: 'bx-message-rounded-dots' },
    ]
  }

  async $start() {
    await api.post(`batches/${this.id}/start`)

    return true
  }

  async $pause() {
    await api.post(`batches/${this.id}/pause`)

    return true
  }
}

Resource.registerResource(Batch)